import { render, staticRenderFns } from "./SocialList.vue?vue&type=template&id=5802ccde&scoped=true&"
import script from "./SocialList.vue?vue&type=script&lang=js&"
export * from "./SocialList.vue?vue&type=script&lang=js&"
import style0 from "./SocialList.vue?vue&type=style&index=0&id=5802ccde&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5802ccde",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShieldPicto: require('/home/aydistribution/www/www.mybeers.fr/staging/components/ShieldPicto.vue').default})
