/* eslint-disable */
if (typeof document === 'object' && !process.env.AXEPTIO_DISABLED) {
  const el = document.createElement('script')
  el.setAttribute('src', 'https://static.axept.io/sdk.js')
  el.setAttribute('type', 'text/javascript')
  el.setAttribute('async', true)
  el.setAttribute('data-id', '61fe4d0c0089c7a0010d4403')
  el.setAttribute('data-cookies-version', 'mybeers-fr')
  if (document.body !== null) {
    document.body.appendChild(el)
  }
}

// Save user preferences
if (typeof window === 'object') {
  void 0 === window._axcb && (window._axcb = [])
  window._axcb.push(function (axeptio) {
    axeptio.on('cookies:complete', function (choices) {

      if (choices.google_ads) {
        launchGA();
      }

    })
  })

  function launchGA(){

    const el = document.createElement("script");
    el.setAttribute('type', 'text/javascript');
    el.setAttribute('async', true);
    el.setAttribute('src', '//www.googletagmanager.com/gtag/js?id=AW-10933821033');
    document.body.append(el);
    window.dataLayer = window.dataLayer || [];

    window.gtag = function() {
      window.dataLayer.push(arguments);
    };

    gtag('js', new Date());
    gtag('config', 'AW-10933821033', { 'anonymize_ip': true ,'link_attribution': true });
  }

  // https://support.axeptio.eu/hc/fr/articles/8610881942545-Intégration-Matomo-Tag-Manager
  // test url : http://192.168.31.150:3000/?mtmPreviewMode=w38zab8J
  window._axcb = window._axcb || []
  window._mtm = window._mtm || []
  window._axcb.push(function (sdk) {
    sdk.on('cookies:complete', function (choices) {
      let axeptio_Matomo = []
      for (let vendor in choices) {
        if (vendor != '$$completed' && choices[vendor] == true) {
          _mtm.push({ event: `axeptio_activate_${vendor}` })
          axeptio_Matomo.push(vendor)
        }
      }
      _mtm.push({ axeptio_Matomo: axeptio_Matomo })
    })
  })
}
