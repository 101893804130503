import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=3bbe18c8&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=3bbe18c8&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bbe18c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThePopin: require('/home/aydistribution/www/www.mybeers.fr/staging/components/ThePopin.vue').default,ButtonClose: require('/home/aydistribution/www/www.mybeers.fr/staging/components/ButtonComponents/ButtonClose.vue').default,ButtonArrow: require('/home/aydistribution/www/www.mybeers.fr/staging/components/ButtonComponents/ButtonArrow.vue').default,GalleryLightBox: require('/home/aydistribution/www/www.mybeers.fr/staging/components/GalleryComponents/GalleryLightBox.vue').default})
