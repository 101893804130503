import { render, staticRenderFns } from "./PopinNavigation.vue?vue&type=template&id=6c37336c&scoped=true&"
import script from "./PopinNavigation.vue?vue&type=script&lang=js&"
export * from "./PopinNavigation.vue?vue&type=script&lang=js&"
import style0 from "./PopinNavigation.vue?vue&type=style&index=0&id=6c37336c&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c37336c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonArrow: require('/home/aydistribution/www/www.mybeers.fr/staging/components/ButtonComponents/ButtonArrow.vue').default})
