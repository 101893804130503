
import smoothscroll from 'smoothscroll-polyfill'

import { mutations, store } from '@/assets/scripts/store'
import { IS_BRANCH_STAGING } from '@/assets/scripts/utils'

// eslint-disable-next-line
typeof window !== 'undefined' ? smoothscroll.polyfill() : null

export default {
  name: 'Default',

  data() {
    return {
      navigationOpened: false,
    }
  },

  computed: {
    isMobile() {
      return store.isMobile
    },
    isPopinOpen() {
      return store.popinOpened
    },
    lightboxImgs() {
      return store.lightboxImgs
    },
    lightboxIndex() {
      return store.lightboxIndex
    },
    popinData: mutations.getPopinData,
    popinComponent: mutations.getPopinComponent,
    popinNavigationItems: mutations.getPopinNavigationItems,
    isDraft: mutations.isDraft,
    shouldDisplayForm() {
      return this.$route.name !== 'mentions-legales'
    },
  },

  watch: {
    $route(to) {
      if (to.hash.indexOf('#goto:') === 0) {
        this.$router.push({ hash: null })
        setTimeout(() => {
          this.requestForm()
        }, 1)
      }
    },

    navigationOpened(value) {
      if (typeof document === 'undefined') return

      if (value) {
        document.body.classList.add('navigation-opened')
      } else {
        document.body.classList.remove('navigation-opened')
      }
    }
  },

  created() {
    this.$router.afterEach(() => {
      this.updatePopinStateFromURL()
    })
    this.updatePopinStateFromURL()
    this.draftMentionEnabled = IS_BRANCH_STAGING
  },

  mounted() {
    if (window) {
      this.resizeHandler = () => {
        mutations.setScreenLarge(window.innerWidth >= 1024)
        mutations.setTouchDevice(
          'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
        )
        mutations.setMobile(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      }
      this.resizeHandler()
      window.addEventListener('resize', this.resizeHandler)
    }
  },

  beforeDestroy() {
    if (window) {
      window.removeEventListener('resize', this.resizeHandler)
    }
  },

  methods: {
    updatePopinStateFromURL() {
      this.$route.params.popin ? mutations.openPopin() : mutations.closePopin()
    },

    requestPopinClose() {
      if (this.$route.params.popin) {
        if (this.$route.name === 'portrait-popin') {
          this.$router.push({ name: 'index', params: { popin: null }})
          return
        }

        this.$router.push({ params: { popin: null } })
        return
      }
      mutations.closePopin()
    },

    requestLightBoxClose() {
      mutations.closeLightBox()
    },

    requestForm() {
      if (
        this.$route.name !== 'contact' &&
        this.$route.name !== 'mentions-legales'
      ) {
        setTimeout(() => {
          this.$refs.form.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      } else {
        this.$router.push('/#goto:deviens-franchise')
      }
    },
  },
}
